
import Vue from 'vue';

type Props = {
    items: string[];
    activeItemIndex: number;
}

type NoData = Record<string, never>;

export default Vue.extend<NoData, NoData, NoData, Props>({
    props: {
        items: {
            type: Array,
            required: true,
        },
        activeItemIndex: {
            type: Number,
            required: true,
        },
    },
});
